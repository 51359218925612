import Image from 'next/image';
import { Context } from 'context/Context';
import { useContext } from 'react';
import { Header, Footer, TextFooter, ContainerOptions, ContainerContent, TitleDisabled, Subtitle, ContainerButton, Button, ButtonText } from './styled';
import { FormattedMessage } from 'react-intl';

function HomeScreenDisabled({ dataShop }) {
  const { pais, router } = useContext(Context);
  const countriesName = {
    1: 'ar',
    2: 'mx',
    180: 'pe',
    46: 'co',
    59: 'ec'
  };
  const shopName = dataShop?.name;

  return (
    <div>
      <Header>
         <Image src='/imgs/logoComplete.svg' width={148} height={48} alt='logo' />
      </Header>
      <ContainerContent>
        <Image
          src='/imgs/ilustrationShopDisabled.svg'
          alt='image'
          width={200}
          height={212}
        />
        <TitleDisabled>
        <FormattedMessage
          id='titledisabled'
          defaultMessage="The mercadito {shopName} closed"
          values={{ shopName: shopName }}
        />
        </TitleDisabled>
        <Subtitle>
          <FormattedMessage
          id='subtitledisabledshopid1'
          defaultMessage="Don't worry! You can find quality products at a good price in other Mercadito."
          />
        </Subtitle>
        <Subtitle>
          <FormattedMessage
          id='subtitledisabledshopid2'
          defaultMessage="Find the nearest shop on the map."
          />
        </Subtitle>
        <ContainerButton>
          <Button onClick={()=> router.push(`/${countriesName[pais]}/selector`)}>
            <ButtonText>
              <FormattedMessage
              id='subtitledisabledshopid3'
              defaultMessage="Find Mercadito"
              />
            </ButtonText>
          </Button>
        </ContainerButton>
      </ContainerContent>
      <Footer>
            <div>
              <Image src='/imgs/logoComplete.svg' width={148} height={48} alt='logo' />
            </div>
            <ContainerOptions>
              <TextFooter onClick={()=> router.push(`/${countriesName[pais]}/about`)}>
                  <FormattedMessage
                    id='labelAboutMercadito'
                    defaultMessage='About Mercadito'
                  />
                </TextFooter>
                <TextFooter  onClick={()=> countriesName[pais] === 'mx' ? window.open("https://sumate.el-mercadito.co/mx/v2", "sumate.el-mercadito/co") : window.open("https://sumate.el-mercadito.co/ar/v2", "sumate.el-mercadito/co")}>
                  <FormattedMessage
                  id='labelOpenYourOwn'
                  defaultMessage='Open your own Mercadito'
                  />
              </TextFooter>
            </ContainerOptions>
            <div style={{display: 'flex', width: '100%'}}>
                <div style={{display: 'flex', alignItems: 'center', cursor:'pointer'}} onClick={()=> countriesName[pais] === 'mx' ? window.open("https://www.facebook.com/profile.php?id=100080200750387", "facebook.com") : window.open("https://www.facebook.com/profile.php?id=100075961225244", "facebook.co")}>
                    <Image src='/imgs/facebookIcon.svg' width={16} height={16} alt='facebookIcon' />
                    <TextFooter style={{margin: '0px 0px 0px 4px'}}>Facebook</TextFooter>
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginLeft:'24px', cursor:'pointer'}} onClick={()=> countriesName[pais] === 'mx' ? window.open("https://www.instagram.com/mercaditomexicoof/", "instagram.com") : window.open("https://www.instagram.com/mercaditoar/", "instagram.co")}>
                    <Image src='/imgs/instagramIcon.svg' width={16} height={16} alt='instagramIcon' />
                        <TextFooter style={{margin: '0px 0px 0px 4px'}}>Instagram</TextFooter>
                </div>
            </div>
      </Footer>
  </div>
  );
}
export default HomeScreenDisabled;