import { useContext, useEffect, useState } from "react";
import { Context } from "context/Context";
import HomeScreenByStatus from "componentes/HomeScreenByStatus";
import { activeCountries, maintenanceMode, tierAdviceText } from "services/helpers";
import LandingCountry from "componentes/LandingCountry";
import validateCoupon from "services/validatecoupon";
import ModalCouponErrorFirstPurchase from "componentes/ModalCouponErrorFirstPurchase";
import { useIntl } from "react-intl";
import couponErrorMessage from "services/couponerrormessage";
import SnackBar from "componentes/SnackBar";
import UnderMaintenance from "componentes/UnderMaintenance";
import Loader from "componentes/Loader";
import { onesignal } from "services/onsesignal";
import OneSignal from "react-onesignal";
import useFeatureFlags from "componentes/useFeatureFlags";

export default function Tienda({ route, coupon, id }) {
  const intl = useIntl();
  const [couponError, setCouponError] = useState(null);
  const [errorFirstPurchase, setErrorFirstPurchase] = useState(false);
  const [showCouponPopUp, setShowCouponPopUp] = useState(null);
  const [countryMaintenanceMode, setCountryMaintenanceMode] = useState(null);
  const oneSignalFF = useFeatureFlags('onesignal');

  let {
    setSucursal,
    dataShop,
    categories,
    isCatalogSG,
    user,
    showTierAdvice,
    setShowTierAdvice,
    pais
  } = useContext(Context);

  useEffect(() => {
    dataShop && setCountryMaintenanceMode(maintenanceMode[dataShop.country_id])
  }, [dataShop]);

  useEffect(() => {
    if (pais && typeof window !== "undefined" && oneSignalFF) {
      try {
        const oneSignalAppId = onesignal(pais);
        OneSignal.init({
          appId: oneSignalAppId,
          serviceWorkerParam: { scope: "/" },
          serviceWorkerPath: "/sw.js",
        },
      ).then(async () => {
          const slidedownOptions = {
            force: true,
          };

          await OneSignal.Slidedown.promptPush(slidedownOptions); 

          if (user?.customer_id) {
            OneSignal.User.addTag("customer_id", user.customer_id);
          }
        });
      } catch (error) {
        console.error("Error inicializando OneSignal:", error);
      }
    }
  }, [pais, user, oneSignalFF]);

  useEffect(() => {
    showCouponPopUp &&
      setInterval(() => {
        setShowCouponPopUp(false);
      }, 3000);
  }, [showCouponPopUp]);

  useEffect(() => {
    !activeCountries.includes(route.toLowerCase())
      ? setSucursal(route.toLowerCase())
      : localStorage.removeItem("shop");
  }, [route]);

  useEffect(() => {
    if (coupon && dataShop && user !== null && window) {
      validateCoupon(dataShop?.country_id, dataShop?.id, coupon, 0, "", user?.customer_id).then(
        (res) => {
          if (res.status === 200) {
            localStorage.setItem("coupon", coupon);
            setShowCouponPopUp(true);
          } else {
            setCouponError(res);
            if (res.error_code === 2) {
              localStorage.setItem("coupon", coupon);
              setShowCouponPopUp(true);
            } else if (res.error_code === 128) {
              if (res.value !== "") {
                localStorage.removeItem("coupon");
                setErrorFirstPurchase(true);
              } else {
                localStorage.setItem("coupon", coupon);
                setShowCouponPopUp(true);
              }
            } else {
              localStorage.removeItem("coupon");
              setErrorFirstPurchase(true);
            }
          }
        }
      );
    }
  }, [coupon, dataShop, user]);
  if (activeCountries?.includes(route)) return <LandingCountry country={route} />;
  if (countryMaintenanceMode) return <UnderMaintenance />;
  if (categories && dataShop)
    return (
      <>
        <div>
          <HomeScreenByStatus
            dataShop={dataShop}
            categories={categories}
            sucursal={route}
            isCatalogSG={isCatalogSG}
            cartIds={id}
          />
          {errorFirstPurchase && (
            <ModalCouponErrorFirstPurchase
              text={couponErrorMessage(couponError, intl)}
              closeModal={() => setErrorFirstPurchase(false)}
              handleClick={() => setErrorFirstPurchase(false)}
            />
          )}
          {showCouponPopUp && (
            <SnackBar
              setShowPopUp={setShowCouponPopUp}
              title={
                "Se aplicó el cupón correctamente. Puedes iniciar tu compra."
              }
            />
          )}
        </div>
        {showTierAdvice && (
          <SnackBar
            title={tierAdviceText(pais)}
            setShowPopUp={setShowTierAdvice}
            link={true}
            top={true}
            polygon={true}
          />
        )}
      </>
    );
  return <Loader />;
}

export async function getServerSideProps(context) {
  try {
    const { params, query } = context;
    const { route } = params;
    const { coupon, id } = query;
    return { props: { route: route, coupon: coupon ?? null, id: id ?? null } };
  } catch (error) {
    console.log('Error fetching data', error);
    return { props: { route: null, coupon: null, id: null } };
  }
}
