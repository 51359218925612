import styled from  'styled-components'
import { textDark, fontPrimary, primaryGreen, fillDark } from '../Styles/Global'

export const ContainerModal = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-color:rgb(48, 54, 64, 0.8);
height: 100vh;
width: 100vw;
position: fixed;
top: 0;
z-index: 40;
`
export const ContainerAlert = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px;
gap: 12px;
width: 312px;
height: auto;
opacity: 1;
background-color: ${props => props.theme.white};
border-radius: 8px;
`

export const TitleAlert = styled.div`
color: ${textDark};
font-family: ${fontPrimary};
font-weight: 600;
font-size: 20px;
line-height: 26px;
display: flex;
align-items: flex-start;
`
export const TitleText = styled.div`
color: ${textDark};
font-family: ${fontPrimary};
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 26px;
padding-right: 2%;
`

export const CloseTab = styled.div`
left: 20.83%;
right: 20.83%;
top: 20.83%;
bottom: 20.83%;
`
export const TextDifference = styled.div`
color: ${textDark};
font-family: ${fontPrimary};
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
`

export const ContainerButtons = styled.div`
padding: 5% 0 0 0;
`

export const ButtonMoreProducts = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px;
gap: 10px;
width: 280px;
height: 44px;
background: ${primaryGreen};
border-radius: 8px;
color: ${props => props.theme.white};
font-family: ${fontPrimary};
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 16px;
`

export const ButtonContinue = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px;
margin-top: 3%;
gap: 10px;
width: 280px;
height: 44px;
border: 2px solid ${primaryGreen};
border-radius: 8px;
color: ${primaryGreen};
font-family: ${fontPrimary};
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 16px;
`


