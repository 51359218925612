import styled from "styled-components";

export const Container = styled.a`
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    background: #E5F3EC;
    border: 1px solid #008648;
    color: #008648;
    border-radius: 9999px;
    cursor: pointer;
    gap: 4px;
`;