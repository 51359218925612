import { track } from 'services/gtm';

export function trackRecommendationBlockViewed(carouselType) {
  track('recommendation_block_viewed', {
    event: 'recommendation_block_viewed',
    carouselType: carouselType
  });
}

export function onboardingLoaded(userId, active) {
  track('onboarding_loaded', {
    ecommerce: {
      view: 'onboarding_loaded',
      user_id: userId,
      active: active
    }
  });
}

export function onboardingStatus(userId, status, step) {
  track('onboarding_status', {
    ecommerce: {
      view: 'onboarding_status',
      user_id: userId,
      status: status,
      step: step
    }
  });
}