import { normalizePhone } from "componentes/UserPhone/helpers"

export default async function validateCoupon(country, shopId, coupon, total, phone, customerId) {
    const response = await fetch(`/api/validatecoupon/${country}/${shopId}`, {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
            coupon: coupon.trim(),
            total,
            customer_identification: customerId ? {
                customer_id: customerId
            } : {
                customer_phone: normalizePhone(country, phone)
            }
        })
    })
    const data = await response.json()
    return ({...data, status: response.status})
}