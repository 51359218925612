import styled, { css }  from 'styled-components'

export const ContainerCarousel = styled.ul`
width: ${props => `${props.windowWidth}px`};
max-width: 490px;
height: auto;
overflow-x: auto;
overflow-y: hidden;
white-space: nowrap;
padding: 0px 16px;
display: flex;
margin-top: 8px;
::-webkit-scrollbar {
  display: none;
}
`;

export const ContainerCard = styled.div`
width: 70px;
height: auto;
display: flex;
justify-content: flex-start;
margin-right: ${props => props.last ? '0px' : '8px'};
`

export const ContainerElements = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
overflow: hidden;
cursor: pointer;
`;

export const ContainerImageCategory = styled.div`
width: 64px;
height: 64px;
background-color: #f0f0f0;
border-radius: 100%;
border: 1px solid #F2F3F3;
background: #FFF;
display: flex;
justify-content: center;
align-items: center;
` 

export const Container = styled.div`
height: 100%;
padding-top: 16px;
`;

export const Title = styled.p`
color: #011D0E;
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 20px;
`;


export const ContainerHeader = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding: 0px 16px;
`

export const ButtonAllProducts = styled.div`
color: #00904B;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 800;
line-height: normal;
&:active {
    opacity: 0.6
};
cursor: pointer;
`

export const TitleCategory = styled.p`
  color: #303640;
  text-align: center;
  font-family: sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  max-height: 36px;
  width: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 4px;
  ${({ isSingleWord }) =>
    isSingleWord ? `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    ` : `
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
    `
  }
`;

export const TextCard = styled.p`
color: #011D0E;
font-family: sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 800;
letter-spacing: 0.12px;
margin-right: 4px;
margin-top: -2px;
`

export const CardEmpty = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 64px;
min-width: 64px;
height: 64px;
background-color: #f0f0f0;
border-radius: 100%;
border: 1px solid #F2F3F3;
background: #FFF;
position: relative;
&:active{
  opacity: 0.5;
};
cursor: pointer;
`