import Image from 'next/image';
import styles from 'styles/HomeClose.module.scss';
import { Context } from 'context/Context';
import { useContext } from 'react';

function HomeScreenPending({ dataShop }) {
  const { communityLeader } = useContext(Context);
  return (
    communityLeader &&
    <div className={styles.container}>
    <Image
      src='/imgs/shop-mantenimiento.png'
      alt='close'
      height={180}
      width={180}
    />
    <p className={styles.title}>¡Lo sentimos!</p>
    <p className={styles.subtitle}>
      El mercadito de {dataShop.name}, <br />
      se encuentra en mantenimiento.
    </p>
    <div className={styles.button}>
      <a
        href={`https://wa.me/${communityLeader.phone}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        <p className={styles.textButton}>
          Cosulta a {dataShop.name}{' '}
        </p>
      </a>
    </div>
  </div>
  );
}
export default HomeScreenPending;