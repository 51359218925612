import styled, { css } from "styled-components";

export const ContainerBanners = styled.ul`
  overflow-x: scroll;
  overflow-y: hidden;
  transform-origin: top left;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
  width: 100%;
  scroll-snap-type: x mandatory;
  margin-top: 16px;
`;

export const Banner = styled.li`
  ${(props) =>
  props.paddingLeft &&
  css`
  padding-left: 16px
  `};
  padding-right: ${props => props.paddingRight ? '16px' : '8px'};
  display: inline-block;
  position: relative;
  border-radius: 12px;
  scroll-margin: 24px;
  scroll-snap-align: start;
`;

export const ContainerSingleBanner = styled.div`
  width: 100%;
  height: auto;
  padding: 16px 16px 0px 16px;
`;

export const ContainerElipses = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  border-radius: 12px;
  width: 244px;
  height: 160px;
  object-fit: cover;
  box-shadow: 0px 6px 10px rgba(48, 54, 64, 0.04);
`;

export const ImageDot = styled.img`
  ${(props) =>
    props.margin &&
    css`
      margin-right: 16px;
    `}
`;

export const ImageOnlyBanner = styled.img`
  border-radius: 12px;
  object-fit: cover;
  box-shadow: 0px 6px 10px rgba(48, 54, 64, 0.04);
`;
