import HomeScreenActive from 'componentes/HomeScreenActive';
import HomeScreenPending from 'componentes/HomeScreenPending';
import HomeScreenClose from 'componentes/HomeScreenClose';
import HomeScreenDisabled from 'componentes/HomeScreenDisabled';
import { useQuery } from 'react-query';
import { useContext } from 'react';
import { Context } from 'context/Context';
import getAccountSummaries from 'services/getaccountsummaries';
import ProgressBar from "componentes/ProgressBar";

function HomeScreenByStatus({ dataShop, isCatalogSG, categories, cartIds }) {
  let { isSG, pais } = useContext(Context);

  const { data: accountSummaries } = useQuery(
    ['accountSummaries'],
    async () => await getAccountSummaries(dataShop?.community_leader_id),
    {
      enabled:
        dataShop?.status === 'disabled' &&
        !isSG &&
        pais !== 180 &&
        pais !== 46,
    }
  );

  if (dataShop?.status === 'active') return <HomeScreenActive dataShop={dataShop} categories={categories} cartIds={cartIds} />

  if (dataShop.status === 'pending') {
    if (!isCatalogSG) return <HomeScreenPending dataShop={dataShop} />
    else return <HomeScreenActive dataShop={dataShop} categories={categories} />;
  }
  
  if (dataShop?.status === 'disabled' && accountSummaries?.status !== 'OK') return <HomeScreenClose dataShop={dataShop} />;
  
  if (dataShop?.status === 'disabled' && accountSummaries?.status === 'OK') return <HomeScreenDisabled dataShop={dataShop} />;

  return <></>
}

export default HomeScreenByStatus;