import calculateTimeDifferenceInSeconds from "./calculatedtimediscount";
import { finalDiscount } from 'services/producthelpers';

export async function getProductsByCategory(category_id, tierId) {
    const url = (tierId && tierId !== 'not_set') ? `/api/products/category/${category_id}?tierId=${tierId}` :  `/api/products/category/${category_id}`
    const response = await fetch(url, {
        headers: {
            'Access-Control-Allow-Origin': '*',
        }
    });
    const data = await response.json();
    return data.data
}

export async function getProductsInCategories(categories, verified_users, tierId) {
    const url = `/api/products/categories`
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ categories, verified_users, tierId }),
    });
    const data = await response.json()
    return data
}

const orderByCreatedAtProduct = (a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);

    if (dateA > dateB) {
        return -1;
    } else if (dateA < dateB) {
        return 1;
    } else {
        return 0;
    }
  };

export async function getProductsByCatalog(catalog_id, country_id, verified_users, tierId, flash_deals_only, promotions_only) {
    const url = (tierId && tierId !== 'not_set') ? `/api/products/catalog/${catalog_id}?country_id=${country_id}&tierId=${tierId}&flash_deals_only=${flash_deals_only}&promotions_only=${promotions_only}` :  `/api/products/catalog/${catalog_id}?country_id=${country_id}&flash_deals_only=${flash_deals_only}&promotions_only=${promotions_only}`

    const response = await fetch(url, {
        headers: {
            'Access-Control-Allow-Origin': '*',
        }
    });
    
    const data = await response.json();

    if(flash_deals_only) {
        return data;
    }

    const lastPromotions = data ?? []
    lastPromotions?.data?.sort(orderByCreatedAtProduct);

    return lastPromotions?.data ?? [];
}

export async function getProductsByIds(productsIDs, tierId) {
    const url = (tierId && tierId !== 'not_set') ? `/api/products/by-ids?tierId=${tierId}` :  `/api/products/by-ids`
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ productsIDs }),
    });
    const data = await response.json()
    return data.data
}