import { track } from 'services/gtm';

export function trackRepeatLastOrder(order){
  track('repeat_last_order_widget', {
    ecommerce: {
        currency: order.country_id == 1 ? 'ARS' : 'MXN',
        value: Number(order.sum_of_line_totals.value), 
        items: order.lines.map((item, index) => ({
          item_name: item.product_name,
          item_id: item.id,
          price: Number(item.unit_price_without_tax.value),
          index,
          discount: Number(item.discount_value.value),
          currency: order.country_id == 1 ? 'ARS' : 'MXN',
          quantity: Number(item.quantity.value),
        })),
    },
  })
}

export function trackRepeatLastOrderWidgetVisible(){
  track('repeat_last_order_widget_visible', {})
}