import { Context } from 'context/Context';
import { useContext, useEffect, useState } from 'react';
import getmenues from "services/getmenues";
import { FormattedMessage } from 'react-intl';
import { 
    Container,
    ContainerInfo, 
    TitleHeader, 
    ButtonSeeAll, 
    QuantityMenu,
    CardOfMenu,
    MenuName,
    ContainerDataMenu,
    QuantityProducts,
    ContainerImage,
    Divider,
    ContainerButton,
    TextButton
} from './styled';
import Image from 'next/image';
import { getIsLive } from 'services/helpers';

function MenusHome() {
    const { dataShop, router, sucursal, pais } = useContext(Context);
    const [menues, setMenues] = useState(null);

    useEffect(() => {
        if(dataShop){
            getmenues(dataShop.catalog_id).then((data) => setMenues(data));
        }
    },[dataShop]);

    const quantityMenus = menues?.length;

    const sliceMenus = menues?.length && menues?.slice(0, 2);

        const [isLive, setIsLive] = useState(null);
    
        const checkLiveStatus = async () => {
            const isLive = await getIsLive();
            setIsLive(isLive);
          };
    
            useEffect(()=>{
                checkLiveStatus();
            },[isLive]);
    
            const getMenuImage = (menuImage) => {
                if (menuImage) return menuImage;
                if (isLive && pais === 59) return '/imgs/menuEcEs.jpg';
                if (!isLive && pais === 46) return '/imgs/menuEc.jpg';
                return '/imgs/defaultImage.png';
            };

    return (
    quantityMenus > 0 &&
     <Container>
         <ContainerInfo>
            <TitleHeader>
                <FormattedMessage
                  id='MenuTextId'
                  defaultMessage="Menu"                    
                />
                {' '}
               <QuantityMenu>
                    ({quantityMenus})
               </QuantityMenu> 
            </TitleHeader>
            {
            quantityMenus > 2 && (
                <ButtonSeeAll onClick={()=> router.push(`/${sucursal}/menu`)}>
                    <FormattedMessage
                      id='seeAllTextId'
                      defaultMessage="See all"                    
                    />
                </ButtonSeeAll>
            )}
         </ContainerInfo>
         {sliceMenus.map((menu, index)=> {
             return (
                 <>
                 <CardOfMenu onClick={()=> router.push(`/${dataShop.slug}/menu/${menu.id}`)}>
                     <ContainerImage>
                        <Image 
                        src={getMenuImage(menu.image)}
                        width={150} 
                        height={100} 
                        layout="fixed"
                        objectFit="cover"
                        style={{borderRadius:'8px'}}
                        />
                     </ContainerImage>
                     <ContainerDataMenu>
                        <MenuName>
                            {menu.name}
                        </MenuName>
                        <QuantityProducts>
                            /{menu.products.length} 
                                {' '}
                            <FormattedMessage
                            id='productsIdText'
                            defaultMessage='Products'
                            />
                        </QuantityProducts>
                        <ContainerButton>
                            <TextButton>
                            <FormattedMessage
                            id='seeMenuId'
                            defaultMessage='See menu'
                            />
                            </TextButton>
                        </ContainerButton>
                     </ContainerDataMenu>
                 </CardOfMenu>
                 {index !== menues.length -1 && <Divider />}
                 </>
             )
         })}
     </Container>
    );
}

export default MenusHome;
