import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  padding: 0px 17px 0;
  width: 100%;
`;

export const RepeatWidget = styled.div`
border-radius: 8px;
border: 1px solid ${props => props.theme.secondaryDark};
background: #FFF;
height: 56px;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 18px;
`;

export const LeftBlock = styled.div`
display: flex;
align-items: center;
gap: 12px
`;


export const Text = styled.p`
color: var(--Dark-Text-Primary, #001109);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px;
`;