import styled from "styled-components";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 490px;
  height: 100%;
  background: var(--Gray-Primary, #001109);
  opacity: 0.7;
  z-index: 100;
  pointer-events: all;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 490px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const ModalContent = styled.div`
  width: 100%;
  margin: 0 16px;
  padding: 32px 16px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: #ffffff;
  border-radius: 8px;
`;

export const Text = styled.p`
  color: var(--Gray-Primary, #001109);
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Roboto;
  font-size: ${(props) => (props.large ? "20px" : "14px")};
  font-style: normal;
  font-weight: ${(props) => (props.large ? 600 : 400)};
  line-height: ${(props) => (props.large ? "26px" : "18px")};
`;
