import getproduct from "./getproduct";

const cartLinkOrder = async (lines, categories, tierId) => {
    if(!Array.isArray(lines)) {
        lines = [lines]
    }
    const active_products = []
    const inactive_products = []
    const promises = lines.map(async id => {
        const product = await getproduct(id, tierId);
        const categoryId = product.category_id
        const isIncluded = categories.some(category => category.id === categoryId);
        if (!isIncluded) {
            return
        }
        if (product.is_active && product['master-product'].erp_active) {
            active_products.push({ product, quantity: 1, price_difference: 0});
            return
        } 
        if(product?.is_active === false || product['master-product']?.erp_active === false && !product?.message) {
            inactive_products.push({product, quantity: 1});
            return
        } 
        if(product.message) {
            return
        }
    });
    await Promise.all(promises)
    return {active_products, inactive_products}
}

export default cartLinkOrder;