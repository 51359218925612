import { useContext } from 'react';
import styles from 'styles/Header.module.scss';
import { Context } from 'context/Context';

function HeaderSg({ dataShop }) {
  const { fechaEntrega } = useContext(Context);

  return (
    fechaEntrega !== null && (
      <>
        <img
          src='/imgs/ECheader.jpg'
          alt='Header'
          className={styles.imgPortada}
        />
          <figure className={styles.containerImgPerfil}>
            <img
              className={`is-rounded ${styles.imgPerfil} `}
              alt='logo'
              src='/imgs/EClogo.png'
            />
          </figure>
        <div className={styles.containerText} style={{paddingTop:'9vh'}}>
            <h1 className={styles.textTitleName}>
              {dataShop?.name.toUpperCase()}{' '}
              {dataShop?.last_name ? (
                dataShop?.last_name?.toUpperCase()
              ) : (
                <></>
              )}
            </h1>
        </div>
      </>
    )
  );
}
export default HeaderSg;
