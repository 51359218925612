import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
padding: 0px 16px;
margin-top: 16px;
`;

export const ContainerInfo = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 8px;
`

export const TitleHeader = styled.p`
color: ${props => props.theme.textDefault};
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px;
`

export const ButtonSeeAll = styled.div`
color: #00904B;
text-decoration: none;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 800;
line-height: normal;
&:active {
    opacity: 0.6
};
cursor: pointer;
`

export const QuantityMenu = styled.span`
color: ${props => props.theme.textTertiary};
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 16px;
font-family: sans-serif;
`

export const CardOfMenu = styled.div`
border-radius: 8px;
background: #FFF;
position: relative;
display: flex;
justify-content: space-between;
`

export const ContainerDataMenu = styled.div`
display: flex;
flex-direction: column;
width: 60%;
padding: 0px 0px 0px 8px;
justify-content: space-between;
`

export const MenuName = styled.p`
color: #011D0E;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 18px;
`

export const QuantityProducts = styled.p`
color: #344A3E;
font-family: sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 14px;
margin-top: 8px;
`

export const ContainerImage = styled.div`
display: flex;
width: 40%
height: auto;
border-radius: 8px;
`

export const Divider = styled.div`
border-bottom: 1px solid #E5E7E6;
width: 100%;
margin: 12px 0px;
`

export const ContainerButton = styled.div`
border-radius: 6px;
border: 1px solid #00904B;
width: 100%;
height: auto;
display: flex;
justify-content: center;
padding: 8px 0px;
margin-top: 4px;
&:active {
  opacity: 0.6
};
`

export const TextButton = styled.div`
color: #00904B;
text-align: center;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 800;
line-height: 20px; 
`