import { Download } from "lucide-react";
import { Container } from "./styled";
import { tracDownloadClick } from "./tracking";
import { FormattedMessage } from 'react-intl';

const DownloadApp = ({ handleClick, relocateContainer }) => {
  return (
    <Container
      onClick={() => {
        tracDownloadClick();
        handleClick();
      }}
      {...{ relocateContainer }}
    >
      <Download color="#008648" height={20} width={20} />
      <p style={{
          color: "var(--Fill-Primary-action-hover, #006B3A)",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "13px",
      }}>
          <FormattedMessage
              id="downloadOurAppId"
              defaultMessage="Download App"
            />
      </p>
    </Container>
  );
};

export default DownloadApp;
