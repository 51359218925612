import styled from "styled-components";


export const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
min-height: 100vh;
align-items: center;
height: 100%;
`
export const TextContainer = styled.div`
padding: 0px 52px;
margin-top: 112px
`

export const Header = styled.div`
padding: 0px 16px;
width: 100%;
display: flex;
background-color: ${props => props.theme.secondary};
height: 64px;
align-items: center;
z-index: 2;
`

export const Title = styled.h1`
color: #000;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 26px; /* 130% */
margin-top: 72px
`

export const Subtitle = styled.p`
color: #000;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 137.5% */;
margin-top: 16px
`