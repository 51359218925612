import style from 'styles/ProductId.module.scss';
import styles from 'styles/CardCategory.module.scss';
import { Context } from 'context/Context';
import { useContext } from 'react';
import GridCards from 'componentes/GridCards';
import { isCatalog } from 'services/featureflags';

function CardCategoryList({ categories }) {
  let { fechEntrega, isSG, isCatalogSG } = useContext(Context);
  return (
    fechEntrega !== null && (
      <div
        className={isSG ? styles.containerB2b : styles.container}
      >
        <div>
          <GridCards items={categories || []} />
        </div>
      </div>
    )
  );
}

export default CardCategoryList;
