import React, { useEffect, useRef, useContext } from 'react';
import { Context } from 'context/Context';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useRouter } from "next/router";
import { homeBannerClicked, homeBannerScrolled, homeBannerLoaded } from 'componentes/BannersSlider/tracking';
import '@splidejs/react-splide/css';
import Image from 'next/image';

const BannersHero = () => {
  let { sucursal, bannerHero } = useContext(Context);
  const router = useRouter();
  const ref = useRef();

  const arrIds = bannerHero?.map((banner) => banner?.banner.id);

  useEffect(() => {
    if(bannerHero?.length){
        homeBannerLoaded(arrIds, 1, 'hero');
    }
  }, []);

  useEffect(() => {
    const splideInstance = ref.current.splide;

    splideInstance.on('dragged', () => {
      const currentIndex = splideInstance.index;
      const currentBanner = bannerHero[currentIndex]?.banner;
      homeBannerScrolled(arrIds, currentBanner.id, 'hero');
    });

    return () => {
      splideInstance.off('dragged');
    };
  }, []); 

  if (bannerHero?.length === 1) {
    return (
    <Splide       
    options={{
        rewind: true,
        gap: '1rem',
        pagination: false,
        width: '100%',
        heightRatio: 0.6,
    }}
    aria-label="banner-hero"
    ref={ref}
    onClick={()=> {
      const link = bannerHero[0]?.banner?.link_to_shop_path;
      if(link) {
        homeBannerClicked(bannerHero[0]?.banner?.id, link, 1, arrIds, 'hero');
        router.push(`/${sucursal}/${link}`);
      };
    }}
    >
      <SplideSlide key={bannerHero[0]?.banner.id}>
        <Image 
          src={bannerHero[0]?.banner?.banner_image_url} 
          alt={'banner-hero'} 
          width={720} 
          height={432} 
        />
      </SplideSlide>
      <div className="splide__arrows" style={{visibility:'hidden'}} />
      </Splide>
    );
  }

  return (
  <>  
    <Splide       
        options={{
          rewind: true,
          gap: '1rem',
          autoplay: true, 
          interval: 3000,
          pagination: false,
          type: 'fade',
          width: '100%',
          heightRatio: 0.6,
        }}
      aria-label="banner-hero"
      ref={ref}
      >
        {bannerHero?.map((banner, index) => (
            <SplideSlide 
            key={index} 
            onClick={()=> {
              const link = banner?.banner?.link_to_shop_path;
              if(link) {
                homeBannerClicked(banner.banner.id, link, index + 1, arrIds, 'hero');
                router.push(`/${sucursal}/${link}`);
              } 
            }}
            >
                <Image
                  src={banner?.banner?.banner_image_url}
                  alt={`Banner ${index + 1}`}
                  width={720}
                  height={432}
                />
            </SplideSlide>
        ))}
          <div className="splide__arrows" style={{visibility:'hidden'}} />
    </Splide>
  </>
  );
};

export default BannersHero;
