import styled from 'styled-components'

export const ContainerContent = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
padding: 46px 0px;
`

export const Header = styled.div`
padding: 0px 16px;
width: 100%;
display: flex;
background-color: ${props => props.theme.secondary};
height: 64px;
align-items: center;
z-index: 2;
`

export const Footer = styled.div`
background-color: ${props => props.theme.secondary};
width: 100%;
padding: 32px 24px 24px 24px;
display: flex;
height: ${props => props.selector && '190px'};
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
`

export const TextFooter = styled.p`
color: white;
font-size: 16px;
font-family: sans-serif;
font-weight: 600;
margin-bottom: 8px;
&:active {
  opacity: 0.7;
}
cursor: pointer;
`

export const ContainerOptions = styled.div`
height: 80px;
display: flex;
flex-direction: column;
justify-content: center;
`

export const TitleDisabled = styled.p`
color: #000;
text-align: center;
font-family: sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 26px; 
margin-top: 32px;
padding: 0px 40px;
`

export const Subtitle = styled.p`
color: #000;
text-align: center;
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px;
padding: 0px 40px;
margin-top: 16px;
`

export const ContainerButton = styled.div`
display: flex;
justify-content: center;
margin-top: 32px;
`

export const Button = styled.div`
display: flex;
height: 56px;
padding: 16px 32px;
justify-content: center;
align-items: center;
border-radius: 8px;
background: ${props => props.theme.primary};
`

export const ButtonText = styled.p`
color: #FFF;
text-align: center;
font-family: sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 20px; 
`