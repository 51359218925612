import { defineMessages } from 'react-intl';

export default defineMessages({
titleHighlightsHome: {
    id: 'titleHighlightsHome',
    defaultMessage: 'Latest promotions',
  },
titleUnmissablePromotions: {
    id: 'titleUmissablePromotions',
    defaultMessage: 'Unmissable promotions',
  },
titleFlashDeals: {
    id: 'titleFlashDeals',
    defaultMessage: 'Flash deals',
  },
});