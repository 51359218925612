import styled from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color:rgb(48, 54, 64, 0.7);
height: 100vh;
width: 100vw;
position: fixed;
top: 0;
z-index: 40;
max-width: 490px;
`

export const Close = styled.div`
top: 6px;
right: 16px;
position: absolute;
z-index: 3;
`