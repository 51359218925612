import { useRouter } from "next/router";
import 
{
ContainerBanners, 
Banner, 
ContainerSingleBanner, 
ContainerElipses, 
Image, 
ImageDot, 
ImageOnlyBanner 
} from './styled';
import { useCallback, useContext, useRef } from 'react';
import { Context } from 'context/Context';
import { useEffect, useState } from 'react';
import { homeBannerClicked, homeBannerScrolled, homeBannerLoaded } from './tracking'

export default function BannersSlider() {
    let { sucursal, bannerSlider } = useContext(Context);
    const router = useRouter();
    const [scroll, setScroll] = useState(null);
    const [range, setRange] = useState(1);
    const [change, setChange] = useState(false)
    const [test, setTest] = useState([]);
    const ref = useRef();

    const data = bannerSlider ? bannerSlider?.map((banner) => banner?.banner) : [];
  
    const arrIds = data?.map((e) => e.id);
    const scrollElement = document?.querySelector("#scroll-bar");
    const maxScrollLeft = scrollElement?.scrollWidth - scrollElement?.clientWidth;
    const limitImageToWidth = maxScrollLeft / data?.length;

    const getRange = (value) => {
        let ranges = data?.map((val, i)=>(i + 1) * (limitImageToWidth *(i + 1 == data?.length ? 1.2 : 1.2)));
        for (let i = 0; i < ranges?.length; i++)
            if (value <= ranges[i])
                return i + 1
        return 1;
    }


    function removeDuplicates() {
        return test.filter((item, index) => test.indexOf(item) === index);
    }

    let arr = removeDuplicates();
    let maxElementView = Math.max(...arr);
  
    const handleScroll = useCallback(() => {
         setScroll(Math.ceil(ref?.current?.scrollLeft));
      }, []);


    useEffect(() => {
        if(data?.length){
            homeBannerLoaded(arrIds, 1, 'slider');
        }
    },[]);

    useEffect(() => {
        ref?.current?.addEventListener("scroll", handleScroll);
    },[handleScroll]);

    useEffect(() => {
        data?.length && 
            setRange(getRange(scroll));
            setTest(test.concat(range));
    }, [scroll]);

    useEffect(() => {
        if(data?.length > 1 && maxElementView !== -Infinity){
            homeBannerScrolled(arrIds, maxElementView, 'slider');
        }
    },[range]);

    useEffect(() => {
        setTimeout(() => {
            setChange(true);
          }, 1000);
    }, []);
    
    if(data) return (    
    <>
       {data?.length > 1 &&(
           <>
            <ContainerBanners id='scroll-bar' ref={ref}>
                {data.map((banner, i)=> {
                return (
                        <Banner key={banner.id} paddingLeft={i === 0} paddingRight={i === data.length -1}>
                        <Image 
                        onClick={() => {
                        const link = banner.link_to_shop_path;
                        homeBannerClicked(banner.id, link, i + 1, maxElementView, 'slider');
                        if(link) router.push(`/${sucursal}/${link}`);
                        }} 
                        src={banner.banner_image_url} 
                        alt={banner.title} 
                        /> 
                    </Banner>
                )}
                )} 
            </ContainerBanners>
        </>
        )}
        {data?.length === 1 && (
          <ContainerSingleBanner> 
              <ImageOnlyBanner
               onClick={() => {
                const link = data[0].link_to_shop_path;
                homeBannerClicked(data[0].id, link, 1, 1, 'slider');
                if(link) router.push(`/${sucursal}/${link}`);
               }} 
               src={data[0].banner_image_url} 
               alt={data[0].title} />
          </ContainerSingleBanner> 
        )}
        {!data && (
            <></>
        )}
    </>
    )
    return null;
}