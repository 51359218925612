import styled, { css }  from 'styled-components'

export const ContainerCarousel = styled.ul`
width: ${props => `${props.windowWidth}px`};
max-width: 490px;
height: auto;
overflow-x: auto;
overflow-y: hidden;
white-space: nowrap;
padding: 0px 16px;
display: flex;
margin-top: 8px;
::-webkit-scrollbar {
  display: none;
}
`;

export const Container = styled.div`
margin-top: 16px;
height: 100%;
background-color: #EAEFFC;
`;

export const ContainerHeader = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding: 8px 16px;
`

export const Title = styled.p`
color: ${props => props.theme.textDefault};
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 20px;
display: flex;
`;

export const ButtonAllProducts = styled.div`
color: #00904B;
text-decoration: none;
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 800;
line-height: normal;
&:active {
    opacity: 0.6
};
cursor: pointer;
`

// export const Card = styled.div`
// display: flex;
// flex-direction: column;
// align-items: center;
// justify-content: flex-start;
// width: 72px;
// min-width: 72px;
// height: auto;
// margin-right: ${props => props.last ? '0px' : '8px'};
// position: relative;
// `;

export const Card = styled.div`
  flex: 0 0 24%;
  box-sizing: border-box;
  margin-right: ${props => (props.last ? '0px' : '0px')};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 8px;
`;

export const ElipsiLogo = styled.div`
background-color: #FFF;
stroke: #F2F3F3;
border-radius: 100%;
height: 72px;
width: 72px;
display: flex;
justify-content: center;
align-items: center;
`

export const NameBrand = styled.p`
color: ${props => props.theme.textDefault};
text-align: center;
font-family: sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 600;
max-height: 36px;
overflow: hidden;
text-overflow: ellipsis;
white-space: normal;
line-height: 14px;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
margin-top: 4px;
`