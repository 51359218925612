import { defineMessages } from 'react-intl';

export default defineMessages({
  errorInvalidCodeText: {
    id: 'errorInvalidCodeText',
    defaultMessage: 'Review your coupon code. Coupon could not be redeemed because the code is invalid.',
  },
  errorExpiredCodeText: {
    id: 'errorExpiredCodeText',
    defaultMessage: 'It was not possible to redeem the coupon because the validity date expired.',
  },
  errorNotActiveCodeText: {
    id: 'errorNotActiveCodeText',
    defaultMessage: 'The coupon could not be redeemed because it is not active yet.',
  },
  errorReedemedCodeText: {
    id: 'errorReedemedCodeText',
    defaultMessage: 'The coupon could not be redeemed because the coupon was already redeemed before.',
  },
  errorFirstPurchaseCodeText: {
    id: 'errorFirstPurchaseCodeText',
    defaultMessage: 'It was not possible to redeem the coupon. This coupon is only valid for the first purchase at Mercadito.',
  },
  errorPhoneCodeText: {
    id: 'errorPhoneCodeText',
    defaultMessage: 'You must fill in your WhatsApp number before you can redeem this coupon.',
  },
  messageToday: {
    id: 'messageToday',
    defaultMessage: 'Today',
  },
  messageYesterday: {
    id: 'messageYesterday',
    defaultMessage: 'Yesterday',
  }
});
