import { useContext, useState, useEffect} from "react";
import { Context } from "context/Context";
import { ContainerElements, ContainerImageCategory, ContainerCarousel, Container, ContainerHeader, Title, ButtonAllProducts, TitleCategory, ContainerCard} from './styled';
import { capitalizeFirstLetter } from 'services/helpers'
import Image from "next/image";
import { FormattedMessage } from 'react-intl';
import { track } from "services/gtm";

const CategoriesCarousel = () => {
    const { categories, sucursal, router } = useContext(Context);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
      const visibleCategories = categories?.slice(0, 9);

      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      const showButtonSeeAll = categories?.length > 8;
  
      useEffect(() => {
        window.addEventListener('resize', handleResize);
      
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


  const handleClick = (category_id) => {
    router.push(`/${sucursal}/${category_id}`);
  };

  const isLongSingleWord = (text, maxLength = 9) => {
    return !text.includes(" ") && text.length > maxLength;
  };

    return (
    <Container>
          <ContainerHeader>
          <Title>
          <FormattedMessage
          id='featuedCategoriesId'
          defaultMessage="Categories"                    
          />
          </Title>
          {showButtonSeeAll && (
              <ButtonAllProducts onClick={()=> {
                track('all_categories_click', {})
                router.push(`/${sucursal}/categories`)
              }}>
                  <FormattedMessage
                  id='seeAllTextIdCategories'
                  defaultMessage="See more"                    
                  />
            </ButtonAllProducts>
            )}
         </ContainerHeader>
      <ContainerCarousel windowWidth={windowWidth}>
        <div style={{display:'flex', flexDirection:'column'}}>
        <div style={{display: 'flex', alignItems:'flex-start', justifyContent:'flex-start'}}>
      {visibleCategories?.map((item, i)=> {
              return(
                <div key={item.id}>
                  <ContainerCard onClick={() => handleClick(item.id)}>
                  <ContainerElements first={i === 0}>
                    <ContainerImageCategory>
                        <Image src={item.image} width={40} height={40} alt={item.name} />
                    </ContainerImageCategory>
                    <TitleCategory isSingleWord={isLongSingleWord(item.name)}>
                      {capitalizeFirstLetter(item.name)}
                    </TitleCategory>
                  </ContainerElements>
              </ContainerCard>
              </div>
              )
          })}
          {showButtonSeeAll && (
            <>
              <ContainerCard last onClick={()=> router.push(`/${sucursal}/categories`)}>
                  <ContainerElements>
                    <ContainerImageCategory>
                    <Image src='/imgs/plusIcon.png' width={40} height={40} alt="arrow" />
                    </ContainerImageCategory>
                        <TitleCategory>
                        <FormattedMessage
                      id='seeAllTextIdCategories'
                      defaultMessage="See more"                    
                      />
                </TitleCategory>
                </ContainerElements>
              </ContainerCard>
            </>
        )}
      </div>
      </div>
      </ContainerCarousel>
    </Container>
    );
  };
  
  export default CategoriesCarousel;