import CardCategoryList from 'componentes/CardCategoryList';
import styles from 'styles/CardCategory.module.scss';

function CategoryList({ categories, pb }) {
  return (
    <div style={{paddingBottom: pb && '100px'}} className={styles.backgroundList}>
      <CardCategoryList categories={categories} />
    </div>
  );
}
export default CategoryList;


