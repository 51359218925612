import HeaderSg from 'componentes/HeaderSg/index';
import CategoryList from 'componentes/CategoryList';
import Banners from 'componentes/BannersSlider';
import SearchButton from 'componentes/SearchButton';

export default function HomePageSg({ dataShop, categories }) {
  return (
    <div style={{display:'flex', flexDirection:'column', alignItems:'center', background:'#fdfdfd'}}>
        <HeaderSg dataShop={dataShop} />
         <div>
              <SearchButton SG />
              <Banners/>
              <CategoryList categories={categories} />
         </div>
    </div>
   )
}

