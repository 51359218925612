import { track } from 'services/gtm';
import { totalDiscountNumber } from 'services/producthelpers';

export function trackAddToCart(currency, cart, total, unavailable_products_omitted) {
  track('add_to_cart', {
    ecommerce: {
      currency: currency,
      value: total,
      unavailable_products_omitted,
      items: cart.map(line => {
        return {
          item_name: line.datos_producto.name,
          item_id: line.datos_producto.id,
          price: line.datos_producto.price,
          discount: totalDiscountNumber(line.datos_producto),
          currency: currency,
          quantity: line.cantidad,
          item_category: line.datos_producto.category_name,
          item_category2: line.datos_producto.category_id,
        }
      })
    }
  })
}

export function trackRemoveFromCart(currency, cart, total) {
  track('remove_from_cart', {
    ecommerce: {
      currency: currency,
      value: total,
      items: cart.map(line => {
        return {
          item_name: line.datos_producto.name,
          item_id: line.datos_producto.id,
          price: line.datos_producto.price,
          discount: totalDiscountNumber(line.datos_producto),
          currency: currency,
          quantity: line.cantidad,
          item_category: line.datos_producto.category_name,
          item_category2: line.datos_producto.category_id,
        };
      }),
    },
  });
}

export function trackUserLogin(userId, newCustomer, phone) {
  track('login', {
    user_id: userId,
    new_customer: newCustomer,
    phone: phone.trim()
  });
}
