import styled from  'styled-components';

export const Container = styled.a`
display: flex;
width: auto;
height: auto;
padding: 8px;
justify-content: space-between;
align-items: center;
border-radius: 100%;
background: ${props => props.theme.secondary};
cursor: pointer;
&:active {
    opacity: 0.6
}
`;