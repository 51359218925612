import Image from "next/image"
import { Container, Close } from "./styled"
import { useContext, useState, useEffect } from 'react';
import { Context } from 'context/Context';

const BannerInApp = () => {
    const { bannerInApp, showBanner, setShowBanner } = useContext(Context)

    useEffect(() => {
        const isBannerClosed = sessionStorage.getItem('bannerClosed');
        if (isBannerClosed === 'true') {
            setShowBanner(false);
        }
    }, []);

    const handleCloseBanner = () => {
        setShowBanner(false);
        sessionStorage.setItem('bannerClosed', 'true');
    };

    useEffect(() => {
        if (bannerInApp?.length === 0) setShowBanner(false);
    }, [bannerInApp]);

    if(showBanner && bannerInApp?.length > 0) return (
            <Container onClick={handleCloseBanner}>
                <div style={{ display: 'flex', position: 'fixed', alignItems: 'center', justifyContent: 'center', zIndex: 2, width: '85%', maxWidth: '490px', height: '90%' }}>
                    <div style={{ position: 'relative' }}>
                        <Close onClick={handleCloseBanner}>
                            <Image src='/imgs/closemodalWhite.svg' width={28} height={28} alt='close' />
                        </Close>
                        <Image src={bannerInApp[0]?.banner?.banner_image_url} height={656} width={300} alt={bannerInApp[0]?.banner?.title} />
                    </div>
                </div>
            </Container>
    );
    return null;
};

export default BannerInApp;
