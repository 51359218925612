import Image from "next/image";
import {
  Container,
  Card,
  IconWrapper,
  Spacer,
  Content,
  MainText,
  SubText,
} from "./styled";
import { FormattedMessage, useIntl } from "react-intl";
import formatNumber from "services/formatNumber";
import { currencies } from 'services/helpers'

export default function YouHaveSave({ country }) {
  const intl = useIntl();
  const moneySaved = 1879073;
  const date = 'February 20 2025';

  const formatDate = (date) => {
    const capitalizedWeekday = intl.formatDate(date, { weekday: 'long', day: 'numeric' }).replace(/^\w/, c => c.toUpperCase());
    const month = intl.formatDate(date, { month: 'long' })
    return `${capitalizedWeekday} de ${month}`
}

  return (
    <Container>
      <Card>
        <IconWrapper>
          <Image
            src="/imgs/you-have-have.png"
            width={60}
            height={60}
            alt='icon'
          />
        </IconWrapper>
        <Spacer />
        <Content>
          <MainText>
              <FormattedMessage
              id="youHaveSavedId2"
              defaultMessage="You have saved"
              />
              {' '}
              {currencies[country]} 
              {formatNumber(moneySaved, country)}
          </MainText>
          <SubText>
            <FormattedMessage id="sinceId2" defaultMessage="Since" />
            {' '}
            {formatDate(date)}
            {' '}
            <FormattedMessage
              id="keepSavingWithUsId2"
              defaultMessage="Keep saving with us!"
            />
          </SubText>
        </Content>
      </Card>
    </Container>
  );
}
