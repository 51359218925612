import { Container } from "./styled";
import Image from "next/image";
import { generateSupportLink } from 'services/helpers';
import useFeatureFlags from "componentes/useFeatureFlags";
import { phoneSupportByCountry } from 'services/helpers';

export default function WsFloat({ countryId }) {
    const showContactSupport = useFeatureFlags('contact_support_widget');
    return (
     <Container
     href={showContactSupport ? generateSupportLink(countryId, 'general', '--Hola! necesito ayuda con...') : `https://wa.me/${phoneSupportByCountry[countryId]}?text=Hola, necesito ayuda con...`}              
     target='_blank'
     rel='noopener noreferrer'
     >
         <Image src='/imgs/wsIcon.svg' alt='ws' width={36} height={36} />
     </Container>
    )
};
