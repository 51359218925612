import Image from "next/image";
import { Container, RepeatWidget, Text, LeftBlock } from "./styled";
import { FormattedMessage } from "react-intl";
import { useEffect } from "react";
import { trackRepeatLastOrderWidgetVisible } from "./track";

const RepeatLastOrder = ({ handleRepeatOrder }) => {
    useEffect(() => {
        trackRepeatLastOrderWidgetVisible();
    }
    , []);
    return (
        <Container>
            <RepeatWidget onClick={handleRepeatOrder}>
                <LeftBlock>
                    <Image src='/imgs/repeat_order_icon.svg' width={32} height={32} alt='repeat' />
                    <Text>
                        <FormattedMessage
                            id='repeatLastOrder'
                            defaultMessage='Repeat last order'
                        />
                    </Text>
                </LeftBlock>
                <Image src='/imgs/chevronRight.svg' width={24} height={24} alt='arrow_right' />
            </RepeatWidget>
        </Container>
    );
}

export default RepeatLastOrder;