import { useContext } from 'react';
import { Context } from 'context/Context';
import ProgressBar from 'componentes/ProgressBar';
import HomePageDtc from 'componentes/HomePageDtc';
import HomePageSg from 'componentes/HomePageSg';

export default function HomeScreenActive({ dataShop, categories, cartIds }) {
  let { isCatalogSG } = useContext(Context);

  return(
    <>
      {(isCatalogSG === null || isCatalogSG === false) ? <HomePageDtc dataShop={dataShop} cartIds={cartIds} categories={categories}/> : <HomePageSg dataShop={dataShop}  categories={categories}/>}
    </>
   )
}

