import styled from  'styled-components';

export const Container = styled.div`
display: flex;
width: auto;
height: auto;
gap: 16px;
position: fixed;
bottom: 20px;
right: 20px;
z-index: 8;
`;