import { Container, Header, ContainerContent, Title, Subtitle, FontBold, Button, ButtonText, Elipse, ElipseText, ContainerElipse,Footer, FontBold2, TextFooter, ContainerOptions, ContainerButton, TitlePageSection, ContainerSection2, Spacer} from './styled';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import DrawerNavigation from 'componentes/DrawerNavigation';

const LandingCountry = ({ country, deliveryPointSelector }) => {
    const router = useRouter();
    return(
        <Container>
            {
                deliveryPointSelector ?
                <DrawerNavigation isSearchButtonMoved routerBack={`/${country}`} /> :
                <Header>
                    <Image src='/imgs/logoComplete.svg' width={148} height={48} alt='logo' />
                </Header>
            }
            <ContainerContent deliveryPointSelector={deliveryPointSelector}>
                <Title>
                    <FormattedMessage
                        id='titleLanding'
                        defaultMessage='Make your weekly purchase saving time and money'
                    />
               </Title>
               <Subtitle>
                    <FormattedMessage
                        id='subtitleLanding'
                        defaultMessage='Find fruits, vegetables, bakery, meats, eggs, cleaning products, personal care, home care and much more.'
                    />
               </Subtitle>
               <div  style={{marginTop: '16px'}}>
               <Image src='/imgs/ilustration.svg' width={212} height={288} alt='ilustration' />
               </div>
               <FontBold>
                    <FormattedMessage
                        id='labelSuitsYou'
                        defaultMessage='Mercadito suits you'
                    />
               </FontBold>
               <ContainerButton>
                <Button onClick={()=> deliveryPointSelector ? router.push(`/${country}`) : router.push(`/${country}/selector`)}>
                    <ButtonText>
                            <FormattedMessage
                                id='labelStartShoppingButton'
                                defaultMessage='Start shopping'
                            />
                        </ButtonText>
                </Button>
               </ContainerButton>

               <Spacer/>

               <TitlePageSection>
                <Title>
                        <FormattedMessage
                            id='labelWhyMercadito'
                            defaultMessage='Why mercadito?'
                        />
                </Title>
               </TitlePageSection>
               <ContainerSection2>
                <Image src='/imgs/ilustration2.svg' width={120} height={120} alt='ilustration2'  style={{marginTop: '30px'}}/>
                <FontBold>
                        <FormattedMessage
                            id='labelFindProducts'
                            defaultMessage='Find quality products that are worth it'
                        />
                </FontBold>
                <Subtitle>
                        <FormattedMessage
                            id='subtitleDiscover'
                            defaultMessage='Discover +800 quality products at affordable prices.'
                        />
                </Subtitle>
               </ContainerSection2>
               <ContainerSection2>
                <Image src='/imgs/ilustration3.svg' width={120} height={120} alt='ilustration3'  style={{marginTop: '30px'}}/>
                <FontBold>
                        <FormattedMessage
                            id='labelPricesThatgood'
                            defaultMessage='Take advantage of prices that are good'
                        />
                </FontBold>
                <Subtitle>
                        <FormattedMessage
                            id='subtitleFindNewDiscounts'
                            defaultMessage='Find new discounts and coupons every week to support the economy of your home.'
                        />
                </Subtitle>
               </ContainerSection2>
               <ContainerSection2>
                <Image src='/imgs/ilustration4.svg' width={120} height={120} alt='ilustration4'  style={{marginTop: '30px'}}/>
                <FontBold>
                        <FormattedMessage
                            id='labelEnjoyShopping'
                            defaultMessage='Enjoy the ease of shopping from home'
                        />
                </FontBold>
                <Subtitle>
                        <FormattedMessage
                            id='subtitleChooseDeliveryPoints'
                            defaultMessage='Choose between +200 delivery points, the one that is most comfortable for you.'
                        />
                </Subtitle>
               </ContainerSection2>
               <ContainerButton>
                <Button onClick={()=> deliveryPointSelector ? router.push(`/${country}`) : router.push(`/${country}/selector`)}>
                    <ButtonText>
                            <FormattedMessage
                                id='labelStartShoppingButton'
                                defaultMessage='Start shopping'
                            />
                        </ButtonText>
                </Button>
               </ContainerButton>

               <Spacer/>

               <TitlePageSection>
                <Title>
                        <FormattedMessage
                            id='labelHowWorkMercadito'
                            defaultMessage='How does it work?'
                        />
                </Title>
               </TitlePageSection>
               <ContainerElipse>
                    <Elipse>
                        <ElipseText>1</ElipseText>
                    </Elipse>
                    <FontBold2>
                        <FormattedMessage
                            id='labelNearestStore'
                            defaultMessage='Choose the nearest store'
                        />
                   </FontBold2>
                   <Image src='/imgs/ilustration5.svg' width={80} height={80} alt='ilustration5'/>
               </ContainerElipse>
               <ContainerElipse>
                    <Elipse>
                        <ElipseText>2</ElipseText>
                    </Elipse>
                    <FontBold2>
                        <FormattedMessage
                            id='labelOrderSaving'
                            defaultMessage='Make your order saving'
                        />
                   </FontBold2>
                   <Image src='/imgs/ilustration6.svg' width={80} height={80} alt='ilustration6'/>
               </ContainerElipse>
               <ContainerElipse>
                    <Elipse>
                        <ElipseText>3</ElipseText>
                    </Elipse>
                    <FontBold2>
                        <FormattedMessage
                            id='labelPayPickUp'
                            defaultMessage='Pay when you pick up the order'
                        />
                   </FontBold2>
                   <Image src='/imgs/ilustration7.svg' width={80} height={80} alt='ilustration7'/>
               </ContainerElipse>
               <ContainerButton>
                <Button onClick={()=> deliveryPointSelector ? router.push(`/${country}`) : router.push(`/${country}/selector`)}>
                    <ButtonText>
                            <FormattedMessage
                                id='labelStartShoppingButton'
                                defaultMessage='Start shopping'
                            />
                        </ButtonText>
                </Button>
               </ContainerButton>
            </ContainerContent>
            <Footer>
                <div>
                      <Image src='/imgs/logoComplete.svg' width={148} height={48} alt='logo' />
                </div>
                <ContainerOptions>
                <TextFooter onClick={()=> router.push(`/${country}/about`)}>
                     <FormattedMessage
                            id='labelAboutMercadito'
                            defaultMessage='About Mercadito'
                      />
                </TextFooter>
                <TextFooter  onClick={()=> country === 'mx' ? window.open("https://sumate.el-mercadito.co/mx/v2", "sumate.el-mercadito/co") : window.open("https://sumate.el-mercadito.co/ar/v2", "sumate.el-mercadito/co")}>
                <FormattedMessage
                            id='labelOpenYourOwn'
                            defaultMessage='Open your own Mercadito'
                />
                </TextFooter>
                </ContainerOptions>
                <div style={{display: 'flex', width: '100%'}}>
                    <div style={{display: 'flex', alignItems: 'center', cursor:'pointer'}} onClick={()=> country === 'mx' ? window.open("https://www.facebook.com/profile.php?id=100080200750387", "facebook.com") : window.open("https://www.facebook.com/profile.php?id=100075961225244", "facebook.co")}>
                        <Image src='/imgs/facebookIcon.svg' width={16} height={16} alt='facebookIcon' />
                        <TextFooter style={{margin: '0px 0px 0px 4px'}}>Facebook</TextFooter>
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', marginLeft:'24px', cursor:'pointer'}} onClick={()=> country === 'mx' ? window.open("https://www.instagram.com/mercaditomexicoof/", "instagram.com") : window.open("https://www.instagram.com/mercaditoar/", "instagram.co")}>
                        <Image src='/imgs/instagramIcon.svg' width={16} height={16} alt='instagramIcon' />
                        <TextFooter style={{margin: '0px 0px 0px 4px'}}>Instagram</TextFooter>
                    </div>
                </div>
            </Footer>
        </Container>
    )
}

export default LandingCountry;