import { useContext } from 'react';
import { Context } from 'context/Context';
import Image from 'next/image';
import { ContainerModal, ContainerAlert, TitleAlert, TitleText, CloseTab, ContainerButtons, TextDifference, ButtonMoreProducts, ButtonContinue } from './styled';

export default function ModalCouponErrorFirstPurchase({ closeModal, handleClick, text }) {
    return (
        <ContainerModal>
            <ContainerAlert>
                <TitleAlert>
                    <TitleText>
                        {text}
                    </TitleText>
                    <CloseTab>
                        <Image src='/imgs/closeTab.svg' width={50} height={50} onClick={closeModal}/>
                    </CloseTab>
                </TitleAlert>
                <ContainerButtons>
                    <ButtonContinue onClick={handleClick}>
                        Continuar sin cupón
                    </ButtonContinue>
                </ContainerButtons>
            </ContainerAlert>
        </ContainerModal>
    )
}