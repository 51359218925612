import { FormattedMessage } from 'react-intl';
import { ContainerCarousel, Container, ContainerHeader, ButtonAllProducts, Title, Card, ElipsiLogo, NameBrand } from './styled';
import { useContext, useState, useEffect } from 'react';
import { Context } from 'context/Context';
import Image from 'next/image';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { trackSelectBrand, trackSelectViewAll } from './track';

function BrandsCarousel() {
  const { brands, router, sucursal, pais } = useContext(Context);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const showButtonSeeAll = brands?.length > 7;

  const handleClickSeeAll = () => {
     router.push(`/${sucursal}/brands`);
  };

  const handleClickBrand = (brand_id) => {
    router.push(`/${sucursal}/brands/${brand_id}`);
 };

 const maxBrands = brands?.slice(0, 7);
  return (
      <Container>
            <ContainerHeader>
            <Title>
               <FormattedMessage
               id='featuredbrandstitle'
               defaultMessage="Featured brands"  
               />
            </Title>
            {
            showButtonSeeAll && (
              <ButtonAllProducts onClick={()=> {
                trackSelectViewAll();
                handleClickSeeAll();
              }}>
                  <FormattedMessage
                  id='seeAllTextIdCategories'
                  defaultMessage="See all"                    
                  />
             </ButtonAllProducts>
            )
          }
            </ContainerHeader>
        <ContainerCarousel windowWidth={windowWidth}>
            {
                maxBrands.map((brand, i)=> {
                    return(
                        <Card key={i} last={i === maxBrands.length -1} onClick={()=> {
                          trackSelectBrand(pais, brand.id, brand.name, 'home');
                          handleClickBrand(brand.id)}}>
                            <ElipsiLogo>
                            {brand.image_url ? (
                                <Image
                                src={brand.image_url}
                                alt='logo'
                                width={52}
                                height={52}
                                layout="fixed"
                                objectFit="cover"
                                />
                            ) : <PhotoSizeSelectActualIcon fontSize='large' color='disabled' />}
                            </ElipsiLogo>
                            <NameBrand>
                                {brand.name}
                            </NameBrand>
                        </Card>
                    )
                })
            }
        </ContainerCarousel>
      </Container>
  );
}

export default BrandsCarousel;
