import Image from "next/image";
import { ModalContainer, ModalContent, Overlay, Text } from "./styled";
import { FormattedMessage } from 'react-intl';

export default function ModalInstallPWA({ setOpenPopupPWA }) {
  return (
    <>
      <Overlay />
      <ModalContainer onClick={() => setOpenPopupPWA(false)}>
        <ModalContent>
          <Image
            src="/imgs/mercadito.svg"
            alt="Mercadito"
            width={56}
            height={56}
          />
          <Text large>
            <FormattedMessage
              id="downloadOurAppId"
              defaultMessage="Download App"
            />
          </Text>{" "}
          <Text>
            <FormattedMessage
              id="firstStepToInstallId"
              defaultMessage="1. You must open Mercadito from Safari. Important: It only works in Safari, not in other browsers."
            />
          </Text>{" "}
          <Text>
            <FormattedMessage
              id="secondStepToInstallId"
              defaultMessage={`2. Tap the "Share" button.`}
            />
          </Text>{" "}
          <Image
            src="/imgs/share-ios.svg"
            alt="Compartir"
            width={280}
            height={89}
          />
          <Text>
            <FormattedMessage
              id="thirdStepToInstallId"
              defaultMessage={`3. Select "Add to Home Screen" from the menu that appears and confirm the installation by tapping "Add" in the top right corner.`}
            />
          </Text>{" "}
          <Image
            src="/imgs/add-to-home.svg"
            alt="Añadir a inicio"
            width={280}
            height={89}
          />
          <Text>
            <FormattedMessage
              id="fourthStepToInstall"
              defaultMessage={`4. Done! The app will appear on your home screen like a regular application.`}
            />
          </Text>{" "}
        </ModalContent>
      </ModalContainer>
    </>
  );
}

