import { useContext, useMemo } from "react";
import { Context } from "context/Context";
import BannersSlider from "componentes/BannersSlider";
import { useEffect, useState } from "react";
import DrawerNavigation from "componentes/DrawerNavigation";
import ProductsCarousel from "componentes/ProductsCarousel";
import messages from "componentes/HomeScreenActive/messages";
import { useIntl } from "react-intl";
import CategoriesCarousel from "componentes/CategoriesCarousel";
import YouHaveSaved from "componentes/YouHaveSaved/index.js";
import { useQuery } from "react-query";
import ProgressBar from "componentes/ProgressBar";
import {
  getProductsByCatalog,
  getProductsInCategories,
} from "services/getproducts";
import { dateSelector } from "services/featureflags";
import MinimumOrderValue from "componentes/MinimumOrderValue";
import BrandsCarousel from "componentes/BrandsCarousel";
import getOrders from "services/getorders";
import getOrder from "services/getmyorder";
import ModalPreviewOrder from "componentes/ModalPreviewOrder";
import repeatOrder from "services/repeatorder";
import { cartLine } from "services/cart";
import { setLocalStorage, getLocalStorage } from "services/localstorage";
import {
  capitalizeFirstLetter,
  getRolloutResult,
  localNames,
} from "services/helpers";
import RepeatLastOrder from "./components/RepeatLastOrder";
import { trackRepeatLastOrder } from "./components/RepeatLastOrder/track";
import { trackAddToCart, trackRemoveFromCart } from "services/tracking";
import cartLinkOrder from "services/cartlink";
import BannersInApp from "componentes/BannersInApp";
import BannersHero from "componentes/BannersHero";
import ModalNoProductsvailable from "componentes/ModalNoProductsAvaliable";
import MenusHome from "componentes/MenusHome";
import WsFloat from "componentes/WsFloat";
import { featureMenues } from "services/featureflags";
import useFeatureFlags from "../useFeatureFlags";
import {
  getRecommendations,
} from "services/retailrocket";
import {
  onboardingStatus,
  onboardingLoaded,
  trackRecommendationBlockViewed,
} from "./tracking";
import { getCookie, setCookie } from "services/cookies";
import dynamic from "next/dynamic";
import DownloadApp from "componentes/DownloadApp";
import ModalIntallPWA from "componentes/ModalIntallPWA";
import { Container } from "./styled";

const categoryMap = {
  basicBasket: {
    1: ["canasta básica", "canasta basica"],
    2: ["canasta básica", "canasta basica"],
  },
  fruver: {
    1: ["frutas y verduras"],
    2: ["frutas y verduras"],
  },
  fru: {
    46: ["frutas"],
    59: ["frutas"],
    180: ["frutas"],
  },
  ver: {
    46: ["verduras y hierbas"],
    59: ["vegetales y hojas"],
    180: ["verduras y hierbas"],
  },
  meats: {
    1: ["carnicería, pescadería y prefritos"],
    2: ["carnicería y pescadería"],
    46: ["carnes, aves y pescados"],
    59: ["carnes, aves y pescados"],
    180: ["carnes, aves y pescados"],
  },
  warehouse: {
    1: ["almacén", "almacen"],
    2: ["abarrotes", "abarrotes"],
    46: ["despensa", "despensa"],
    59: ["despensa", "despensa"],
    180: ["abarrotes", "abarrotes"],
  },
  cleanup: {
    1: ["limpieza"],
    2: ["limpieza"],
    46: ["limpieza"],
    59: ["limpieza"],
    180: ["limpieza"],
  },
};

const categoriesNames = (country, tag) => categoryMap[tag]?.[country] ?? [];

export default function HomePageDtc({ dataShop, cartIds, categories }) {
  let {
    sucursal,
    router,
    cantidad,
    pais,
    minimumOrderValue,
    user,
    setCantidad,
    setCarrito,
    setTotal,
    carrito,
    currentInfoCountry,
    brands,
    handleBorrar,
    deliveryPointSelector,
    userTier,
    modalDeliveryPointSelector,
    showBanner,
    customer,
    showProductFruits,
    setShowProductFruits,
  } = useContext(Context);
  const intl = useIntl();
  const dateSelectorFeature = dateSelector(dataShop?.country_id);
  const [isSearchButtonMoved, setIsSearchButtonMoved] = useState(true);
  const [username, setUsername] = useState(null);

  const [modalPreviewLastOrder, setModalPreviewLastOrder] = useState(false);
  const [newLines, setNewLines] = useState(null);
  const [loader, setLoader] = useState(false);
  const [modalNoProductsAvailable, setModalNoProductsAvailable] =
    useState(false);

  const showWhatsappButton = useFeatureFlags("whatsapp_button");
  const showRepeatOrder = useFeatureFlags("repeat_last_order_button");
  const productFruitsFetureFlag = useFeatureFlags("product_fruit");
  const showCustomerSavings = useFeatureFlags("customer_savings");
  const showDownloadButton = useFeatureFlags("download_button");

  const basicBasketCategories = categoriesNames(pais, "basicBasket");
  const fruverCategories = categoriesNames(pais, "fruver");
  const fruCategories = categoriesNames(pais, "fru");
  const verCategories = categoriesNames(pais, "ver");
  const meatsCategories = categoriesNames(pais, "meats");
  const warehouseCategories = categoriesNames(pais, "warehouse");
  const cleanupCategories = categoriesNames(pais, "cleanup");

  const [deferredPrompt, setDeferredPrompt] = useState(window.deferredPrompt);
  const [isInstalled, setIsInstalled] = useState(false);
  const [openPopupPWA, setOpenPopupPWA] = useState(false);
  const [tour, setTour] = useState(null);
  const [step, setStep] = useState(0);

  const findCategoryId = (categories, categoryNames) => {
    if (categoryNames && categoryNames?.length > 0) {
      return (
        categories?.find((category) =>
          categoryNames.some(
            (name) => category?.name?.toLowerCase() === name.toLowerCase()
          )
        )?.id ?? ""
      );
    }
    return "";
  };

  const categoryForBanner = useMemo(
    () => findCategoryId(categories, basicBasketCategories),
    [categories, basicBasketCategories]
  );

  const categoryForFruver = useMemo(
    () => findCategoryId(categories, fruverCategories),
    [categories, fruverCategories]
  );

  const categoryForFru = useMemo(
    () => findCategoryId(categories, fruCategories),
    [categories, fruCategories]
  );

  const categoryForVer = useMemo(
    () => findCategoryId(categories, verCategories),
    [categories, verCategories]
  );

  const categoryForMeats = useMemo(
    () => findCategoryId(categories, meatsCategories),
    [categories, meatsCategories]
  );

  const categoryForWarehouse = useMemo(
    () => findCategoryId(categories, warehouseCategories),
    [categories, warehouseCategories]
  );

  const categoryForCleanup = useMemo(
    () => findCategoryId(categories, cleanupCategories),
    [categories, cleanupCategories]
  );

  const arrayOfCategories = [
    { id: categoryForBanner, key: "basicBasket" },
    { id: categoryForFruver, key: "fruver" },
    { id: categoryForFru, key: "fru" },
    { id: categoryForVer, key: "ver" },
    { id: categoryForMeats, key: "meats" },
    { id: categoryForWarehouse, key: "warehouse" },
    { id: categoryForCleanup, key: "cleanup" },
  ];

  const finalArrayOfCategories = useMemo(() => {
    return arrayOfCategories.filter((category) => category?.id !== "");
  }, [arrayOfCategories]);

  const { data: productsInCategories } = useQuery(
    ["productsInCategories", userTier],
    async () =>
      await getProductsInCategories(
        finalArrayOfCategories,
        dataShop?.verified_purchase,
        userTier
      ),
    {
      enabled:
        finalArrayOfCategories?.length > 0 &&
        userTier !== "not_set" &&
        dataShop !== null,
    }
  );

  const { data: productsFlashDeals, refetch } = useQuery(
    ["productsFlashDeals", userTier],
    async () =>
      await getProductsByCatalog(
        dataShop?.catalog_id,
        pais,
        dataShop?.verified_purchase,
        userTier,
        true,
        false
      ),
    {
      enabled: dataShop !== null && userTier !== "not_set",
    }
  );

  // const { data : productsLastPromotions } = useQuery(
  //   ['productsLastPromotions', userTier], async () => await getProductsByCatalog(dataShop?.catalog_id, pais, dataShop?.verified_purchase, userTier, false, true)
  // );

  const { data: lastOrder } = useQuery(
    ["lastOrder"],
    async () => await getOrders(user?.customer_id, "", 5, "confirmed"),
    {
      enabled: user !== null && user?.customer_id !== undefined,
    }
  );

  const { data: order } = useQuery(
    ["order"],
    async () => await getOrder(lastOrder?.data[0]?.order.id),
    { enabled: lastOrder?.data?.length > 0 }
  );

  const userId =
    user?.customer_id || localStorage.getItem(localNames.anonymousId) || '';

  const { data: recommendationsPopular } = useQuery(
    ["recommendationsPopular"],
    async () => await getRecommendations(userId, dataShop?.catalog_id, 'popular', 'partnerRecommendations'),
    {
      enabled:
        !!user?.customer_id ||
        (!!localStorage.getItem(localNames.anonymousId) &&
          !!dataShop.catalog_id),
    }
  );

  const { data: recommendationsViewed } = useQuery(
    ["recommendationsViewed"],
    async () => await getRecommendations(userId, dataShop?.catalog_id, 'personalViewed', 'partnerRecommendations'),
    {
      enabled:
        !!user?.customer_id ||
        (!!localStorage.getItem(localNames.anonymousId) &&
          !!dataShop.catalog_id),
    }
  );

  const { data: recommendationsPersonalOrdered } = useQuery(
    ["recommendationsPersonalOrdered"],
    async () => await getRecommendations(userId, dataShop?.catalog_id, 'personalOrdered', 'partnerRecommendations'),
    {
      enabled:
        !!user?.customer_id ||
        (!!localStorage.getItem(localNames.anonymousId) &&
          !!dataShop.catalog_id),
    }
  );

  const { data: recommendationsPopularInterested } = useQuery(
    ["recommendationsPopularInterested"],
    async () => await getRecommendations(userId, dataShop?.catalog_id, 'popularInInterestedCategories', 'partnerRecommendations'),
    {
      enabled:
        !!user?.customer_id ||
        (!!localStorage.getItem(localNames.anonymousId) &&
          !!dataShop.catalog_id),
    }
  );

  useEffect(() => {
    if (recommendationsPopular?.length > 0) {
      trackRecommendationBlockViewed("recommended_products");
    }
  }, [recommendationsPopular]);

  useEffect(() => {
    if (recommendationsViewed?.length > 0) {
      trackRecommendationBlockViewed("viewed_products");
    }
  }, [recommendationsViewed]);

  useEffect(() => {
    if (recommendationsPersonalOrdered?.length > 0) {
      trackRecommendationBlockViewed("recommended_products_personal_ordered");
    }
  }, [recommendationsPersonalOrdered]);

  useEffect(() => {
    if (recommendationsPopularInterested?.length > 0) {
      trackRecommendationBlockViewed("recommended_products_popularInterested");
    }
  }, [recommendationsPopularInterested]);


  useEffect(() => {
    if (cartIds && cartIds?.length > 0) {
      if (carrito?.length > 0) {
        carrito.forEach((line) => {
          handleBorrar(line.productid, line.cantidad, line.subtotal, null);
        });
      }
      cartLinkOrder(cartIds, categories, userTier).then(async (res) => {
        if (res?.active_products.length > 0) {
          setLoader(true);
          let cart = [];
          let total = 0;
          let totalquantity = 0;
          const promises = res.active_products.map(async (item) => {
            const line = cartLine(
              item?.product,
              { name: item?.product.category_name },
              item?.quantity,
              "add"
            );
            cart.push(line);
            total += line.subtotal;
            totalquantity += item.quantity;
          });
          await Promise.all(promises);
          setLoader(false);
          setCarrito(cart);
          setTotal(total);
          setCantidad(totalquantity);
          setLocalStorage(localNames.cart, cart);
          setLocalStorage(localNames.total, total);
          setLocalStorage(localNames.quantity, totalquantity);
          router.push(`/${sucursal}/mi-carrito`);
        }
      });
    }
  }, [cartIds, userTier]);

  const handleRepeatOrder = (step) => {
    setLoader(true);
    repeatOrder(order?.order?.lines, userTier).then(async (res) => {
      trackRepeatLastOrder(order?.order);
      if (res?.active_products.length > 0) {
        setNewLines(res);
        let cart = [];
        let total = 0;
        let totalquantity = 0;
        const promises = res.active_products.map(async (item) => {
          const line = cartLine(
            item?.product,
            { name: item?.product?.category_name },
            item?.quantity,
            "add"
          );
          cart.push(line);
          total += line.subtotal;
          totalquantity += item.quantity;
        });
        await Promise.all(promises);
        if (step !== "addnewcart" && step !== "continue") {
          setLoader(false);
          setModalPreviewLastOrder(true);
        } else {
          if (cantidad > 0 && step !== "continue") {
            setLoader(false);
            setModalPreviewLastOrder(false);
          } else {
            if (cantidad > 0) {
              trackRemoveFromCart(
                currentInfoCountry.currency_code,
                carrito,
                total
              );
            }
            trackAddToCart(
              currentInfoCountry?.currency_code,
              cart,
              total,
              res?.inactive_products.length > 0
            );
            setCarrito(cart);
            setTotal(total);
            setCantidad(totalquantity);
            setLocalStorage(localNames.cart, cart);
            setLocalStorage(localNames.total, total);
            setLocalStorage(localNames.quantity, totalquantity);
            router.push(`/${sucursal}/mi-carrito`);
          }
        }
      } else {
        setLoader(false);
        setModalNoProductsAvailable(true);
      }
    });
  };

  useEffect(() => {
    if (window) {
      const storedUsername = localStorage.getItem("anonymousId");
      setUsername(storedUsername || null);
    }
  }, []);

  const userInfo = {
    username: username,
  };

  const ProductFruits = dynamic(
    () => import("react-product-fruits").then((mod) => mod.ProductFruits),
    { ssr: false }
  );

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/sw.js").then(() => {
        console.log("✅ Service Worker registrado");
      });
    }
  }, []);

  useEffect(() => {
    const checkInstalled = () => {
      const isStandalone = window.matchMedia(
        "(display-mode: standalone)"
      ).matches;

      if (isStandalone || deferredPrompt === null) setIsInstalled(true);
      else setIsInstalled(false);
    };

    checkInstalled();

    const handleAppInstalled = () => {
      setIsInstalled(true);
      setDeferredPrompt(null);
    };

    window.addEventListener("appinstalled", handleAppInstalled);

    return () => {
      window.removeEventListener("appinstalled", handleAppInstalled);
    };
  }, [deferredPrompt]);

  const handleInstallClick = async () => {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) return;

    promptEvent.prompt();
    const { outcome } = await promptEvent.userChoice;
    if (outcome !== "dismissed") {
      setDeferredPrompt(null);
      setIsInstalled(true);
    }
  };

  useEffect(() => {
    const startTour = () => {
      const tours = window.productFruits?.api?.tours?.getTours();
      const tourToCancel = tours?.find(
        (tour) =>
          tour.name ===
          (!customer
            ? `Onboarding ${pais === 1 ? "AR" : pais === 2 && "MX"} (Logged in)`
            : `Onboarding ${
                pais === 1 ? "AR" : pais === 2 && "MX"
              } (Not logged in)`)
      );

      if (tours && tourToCancel?.userState === "not_finished")
        window.productFruits.api.tours.markAs(tourToCancel.id, "skipped");
    };

    if (window.productFruitsIsReady) startTour();
    else {
      window.addEventListener("productfruits_ready", startTour);
      return () => window.removeEventListener("productfruits_ready", startTour);
    }
  }, [customer]);

  useEffect(() => {
    if (showProductFruits) {
      onboardingLoaded(
        customer?.customer_id || localStorage.getItem(localNames.anonymousId),
        true
      );
    } else {
      onboardingLoaded(
        customer?.customer_id || localStorage.getItem(localNames.anonymousId),
        false
      );
    }
  }, [showProductFruits]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const tours = window.productFruits?.api?.tours?.getTours();
      if (!tours) return;

      const tourName = customer
        ? "Onboarding MX (Logged in)"
        : "Onboarding MX (Not logged in)";
      const tourAPI = tours.find((tour) => tour.name === tourName);

      setStep(tourAPI?.currentCard?.name || step);

      if (tour?.userState !== tourAPI?.userState) {
        setTour(tourAPI);
        if (tourAPI?.userState === "skipped")
          onboardingStatus(
            customer?.customer_id ||
              localStorage.getItem(localNames.anonymousId),
            "skipped",
            step
          );
        else if (tourAPI?.userState === "finished")
          onboardingStatus(
            customer?.customer_id ||
              localStorage.getItem(localNames.anonymousId),
            "finished",
            step
          );
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [customer, tour, step]);

  useEffect(() => {
    if (typeof window !== "undefined" && username && productFruitsFetureFlag) {
      const rollout = getRolloutResult(username, 30); /* 30 */
      setShowProductFruits(rollout);
    }
  }, [username, productFruitsFetureFlag]);

  if (dataShop)
    return (
      <>
        {username &&
          !showBanner &&
          showProductFruits &&
          !window.productFruits && (
            <ProductFruits
              workspaceCode={process.env.NEXT_PUBLIC_PRODUCT_FRUITS_WORKSPACE}
              language="es"
              user={userInfo}
              config={{
                disableLocationChangeDetection: true,
                dontDestroy: true,
              }}
            />
          )}
        <div
          style={{
            display: "flex",
            position: "relative",
            paddingBottom: "100px",
            flexDirection: "column",
            alignItems: "center",
            background: "#fdfdfd",
          }}
          id={"customerio"}
        >
          <DrawerNavigation
            isSearchButtonMoved={isSearchButtonMoved}
            dataShop={dataShop}
            onlyDeliveryDate={false}
            quantityOfElementsCart={cantidad}
            router={router}
            sucursal={sucursal}
          />
          <div
            style={{
              paddingTop: !isSearchButtonMoved
                ? dateSelectorFeature && !deliveryPointSelector
                  ? "124px"
                  : "176px"
                : !deliveryPointSelector
                ? "75px"
                : "124px",
            }}
          >
            <BannersInApp />
            <BannersHero />
            {lastOrder &&
              carrito?.length === 0 &&
              lastOrder?.data?.length > 0 &&
              lastOrder?.data[0]?.order?.status === "confirmed" &&
              showRepeatOrder && (
                <div style={{ marginTop: "16px", marginBottom: "8px" }}>
                  <RepeatLastOrder handleRepeatOrder={handleRepeatOrder} />
                </div>
              )}

            <CategoriesCarousel />
            {showCustomerSavings && (
              <>
                <YouHaveSaved country={pais} />
              </>
            )}

            {productsFlashDeals?.data?.length > 2 && (
              <ProductsCarousel
                view="Home"
                title={intl.formatMessage(messages.titleFlashDeals)}
                products={productsFlashDeals.data}
                flashDeals
                refetch={refetch}
                type={"flash_deals"}
              />
            )}

            <BannersSlider />
            {featureMenues(dataShop?.country_id) && <MenusHome />}

            {recommendationsPersonalOrdered?.length > 2 && (
              <ProductsCarousel
                view="Home"
                title={"Compraste recientemente"}
                products={recommendationsPersonalOrdered}
                type={"recommended_products_ordered"}
              />
            )}


            {recommendationsPopularInterested?.length > 2 && (
              <ProductsCarousel
                view="Home"
                title={"Los más relevantes de la tienda"}
                products={recommendationsPopularInterested}
                type={"recommended_products_interested"}
              />
            )}

            {productsInCategories?.basicBasket?.length >= 3 && (
              <ProductsCarousel
                categoryId={categoryForBanner}
                view="Home"
                title={"Canasta básica"}
                products={productsInCategories?.basicBasket}
                type={"basic_basket"}
              />
            )}

            {recommendationsPopular?.length > 2 && (
              <ProductsCarousel
                view="Home"
                title={"Los más vendidos"}
                products={recommendationsPopular}
                type={"recommended_products"}
              />
            )}

            {recommendationsViewed?.length > 2 && (
              <ProductsCarousel
                view="Home"
                title={"Vistos recientemente"}
                products={recommendationsViewed}
                type={"viewed_products"}
              />
            )}

            {productsInCategories?.warehouse?.length >= 3 && (
              <ProductsCarousel
                categoryId={categoryForWarehouse}
                view="Home"
                title={capitalizeFirstLetter(
                  categoriesNames(pais, "warehouse")[0]
                )}
                products={productsInCategories?.warehouse}
                type={"warehouse"}
              />
            )}

            {/* {
              productsLastPromotions?.length > 2 &&
              <ProductsCarousel view='Home' title={intl.formatMessage(messages.titleHighlightsHome)} products={productsLastPromotions} lastPromotions type={'last_promotions'} />
              } */}

            {/* {
                unmissablePromotions?.data?.length > 2 && unmissablePromotionsFlag &&
                <ProductsCarousel view='Home' title={intl.formatMessage(messages.titleUnmissablePromotions)} products={unmissablePromotions.data} unmissable type={'unmissable_products'}  />
              } */}

            {productsInCategories?.fruver?.length >= 3 && (
              <ProductsCarousel
                categoryId={categoryForFruver}
                view="Home"
                title={capitalizeFirstLetter(
                  categoriesNames(pais, "fruver")[0]
                )}
                products={productsInCategories?.fruver}
                type={"fruver"}
              />
            )}

            {productsInCategories?.fru?.length >= 3 && (
              <ProductsCarousel
                categoryId={categoryForFru}
                view="Home"
                title={capitalizeFirstLetter(categoriesNames(pais, "fru")[0])}
                products={productsInCategories?.fru}
                type={"fru"}
              />
            )}

            {productsInCategories?.ver?.length >= 3 && (
              <ProductsCarousel
                categoryId={categoryForVer}
                view="Home"
                title={capitalizeFirstLetter(categoriesNames(pais, "ver")[0])}
                products={productsInCategories?.ver}
                type={"ver"}
              />
            )}

            {productsInCategories?.meats?.length >= 3 && (
              <ProductsCarousel
                categoryId={categoryForMeats}
                view="Home"
                title={"Carnes"}
                products={productsInCategories?.meats}
                type={"meats"}
              />
            )}

            {brands?.length >= 4 && <BrandsCarousel />}
            {productsInCategories?.cleanup?.length >= 3 && (
              <ProductsCarousel
                categoryId={categoryForCleanup}
                view="Home"
                title={capitalizeFirstLetter(
                  categoriesNames(pais, "cleanup")[0]
                )}
                products={productsInCategories?.cleanup}
                type={"cleanup"}
              />
            )}

            {minimumOrderValue > 0 && <MinimumOrderValue />}
            {modalPreviewLastOrder && newLines && (
              <ModalPreviewOrder
                products={newLines}
                closeModal={() => setModalPreviewLastOrder(false)}
                actionContinue={() => handleRepeatOrder("addnewcart")}
                router={router}
                shop={sucursal}
              />
            )}
            {modalNoProductsAvailable && (
              <ModalNoProductsvailable
                closeModal={() => setModalNoProductsAvailable(false)}
              />
            )}
            <Container>
              <>
                {showDownloadButton && !isInstalled && deferredPrompt && (
                  <DownloadApp
                    handleClick={handleInstallClick}
                    relocateContainer={
                      showWhatsappButton && !modalDeliveryPointSelector
                    }
                  />
                )}
                {showWhatsappButton && !modalDeliveryPointSelector && (
                  <WsFloat countryId={dataShop?.country_id} />
                )}
              </>
            </Container>
          </div>
          {openPopupPWA && <ModalIntallPWA {...{ setOpenPopupPWA }} />}
        </div>
      </>
    );
  return <ProgressBar />;
}
