import { track } from 'services/gtm';

export function homeBannerClicked(banner_id, target_url, banner_position, carousel_payload, banner_type) {
  track('home_banner_clicked', {
  banner_id: banner_id,
  target_url: target_url,
  banner_position: banner_position,
  carousel_payload: carousel_payload,
  banner_type,
  });
}

export function homeBannerScrolled(arrOfIds, carousel_payload, banner_type) {
  track('home_banners_scrolled', {
  banners_loaded: arrOfIds,
  carousel_payload: carousel_payload,
  banner_type,
  });
}

export function homeBannerLoaded(arrOfIds, carousel_payload, banner_type) {
  track('home_banners_loaded', {
  banners_loaded: arrOfIds,
  carousel_payload: carousel_payload,
  banner_type,
  });
}