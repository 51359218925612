import messages from './messages'

export default function couponErrorMessage(couponError, intl) {
    let message;
        switch (couponError?.error_code || couponError?.code) {
            case 5:
                message = intl.formatMessage(messages.errorInvalidCodeText);
                break;
            case 4:
                message = intl.formatMessage(messages.errorExpiredCodeText);
                break;
            case 8:
                message = intl.formatMessage(messages.errorNotActiveCodeText);
                break;
            case 16:
                message = intl.formatMessage(messages.errorReedemedCodeText);
                break;
            case 128:
                couponError.value !== '' ?
                message = intl.formatMessage(messages.errorFirstPurchaseCodeText) :
                message = intl.formatMessage(messages.errorPhoneCodeText) 
                break;
        }
    return message;
}