import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 24px 16px 8px;
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  border-radius: 4px;
  background-color: #FFE9F2;
  gap: 8px;
  max-height: 48px;
  position: relative;
`;

export const IconWrapper = styled.span`
  position: absolute;
  bottom: 50%;
  width: 60px;
  height: 60px;
  transform: translateY(50%);
`;

export const Spacer = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
`;

export const MainText = styled.p`
  color: #B11A88;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 14px;
  letter-spacing: -0.2px;
`;

export const SubText = styled.p`
  color: #B11A88;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.2px;
`;