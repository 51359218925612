import Image from "next/image";

import { Container, Header, Subtitle, Title, TextContainer } from "./styled";
import { FormattedMessage } from "react-intl";

const UnderMaintenance = () => {
    return(
        <Container>
            <Header>
                <Image src='/imgs/logoComplete.svg' width={148} height={48} alt='logo' />
            </Header>
            <TextContainer>
                <Image src='/imgs/maintenance_image.svg' width={278} height={281} alt='ilustration' />
                <Title>
                    <FormattedMessage
                        id='titleUnderMaintenance'
                        defaultMessage="We'll be right back"
                    />
                </Title>
                <Subtitle>
                    <FormattedMessage
                        id='subtitleUnderMaintenance'
                        defaultMessage='At this time Mercadito is not available due to scheduled maintenance.'
                    />
                </Subtitle>
                <Subtitle>
                    <FormattedMessage
                        id='subtitleUnderMaintenanceFinal'
                        defaultMessage='Please try again later.'
                    />
                </Subtitle>
            </TextContainer>
        </Container>
    )
}

export default UnderMaintenance;